<template>
  <div
    class="grid col-12 font table-separate"
    style="margin-bottom: 10px; padding: 10px; width: 100%"
    v-for="(item, index) in dados"
    v-show="item.tipo_segmento === 3"
    :key="index"
  >
    <div class="grid justify-content-center align-items-center col-12" v-if="cabecalho">
      <img
        style="width: auto; height: 100px;"
        :src="base_url + '/storage/' + cabecalho.logo"
        alt=""
        class="img"
      />
      <div
        style="margin: 0px !important; text-align: center;"
        v-html="cabecalho.texto_logo"
        class="cabecalho font-cabecalho mr-5 col-12"
      ></div>
    </div>

    <table class="col-12 mt-2 borda-externa" style="min-width: 1500px;">
      <thead class="cor-titulo">
        <tr align="center">
          <th colspan="3">FICHA INDIVIDUAL - {{ item.segmento_escolar }}</th>
        </tr>
      </thead>

      <tbody class="borda-externa">
        <tr>
          <td class="">Aluno(a): {{ item.aluno_nome }}</td>
          <td class="">Data de Nascimento: {{ item.data_nascimento }}</td>
        </tr>

        <tr>
          <td class="">Unidade Escolar: {{ item.escola_nome }}</td>
          <td class="">CPF: {{ item.cpf }}</td>
        </tr>

        <tr>
          <td class="">
            Ano de Escolaridade: {{ item.ano_de_escolaridade }}
            <span v-for="(serie, index) in series" :key="index">
              (){{ serie.nome }}</span
            >
          </td>
          <td class="">RG: {{ item.rg }}</td>
        </tr>

        <tr>
          <td class="">Turno: {{ item.turno }}</td>
          <td class="borda-">Naturalidade: {{ item.naturalidade }}</td>
        </tr>

        <tr>
          <td class="borda-">Turma: {{ item.turma }}</td>
          <td class="borda-">Mãe: {{ item.mae }}</td>
        </tr>
        <tr>
          <td class="borda-">Ano Letivo: {{ item.anoletivo }}</td>
          <td class="borda-">Pai: {{ item.pai }}</td>
        </tr>
        <tr>
          <td class="borda-">
            Total de Dias Letivos: {{ item.total_dias_letivos }}
          </td>
          <td class="borda-" v-if="item.endereco != null">
            Endereço: {{ item.endereco.logradouro }}
          </td>
        </tr>
      </tbody>
    </table>

    <table class="col-12 borda-externa tabela" border="1" style="min-width: 1500px;">
      <thead>
        <tr align="center">
          <th colspan="19">BASE NACIONAL COMUM CURRICULAR</th>
        </tr>
        <tr align="center">
          <th rowspan="2" class="bordas title-padding">
            AREA DE CONHECIMENTO
          </th>
          <th rowspan="2" class="bordas title-padding borda-right">
            COMPONENTES CURRICULARES
          </th>
          <th colspan="3" class="bordas title-padding borda-right" v-if="item.componentes_curriculares[0].p1_letivo">
            1° período
          </th>
          <th colspan="3" class="bordas title-padding borda-right" v-if="item.componentes_curriculares[0].p1_letivo">
            2° período
          </th>
          <th colspan="3" class="bordas title-padding borda-right" v-if="item.componentes_curriculares[0].p3_letivo">
            1° período
          </th>
          <th colspan="3" class="bordas title-padding borda-right" v-if="item.componentes_curriculares[0].p3_letivo">
            2° período
          </th>
          <th colspan="3" class="bordas title-padding borda-right" v-if="!item.componentes_curriculares[0].p3_letivo && !item.componentes_curriculares[0].p1_letivo">
            1° período
          </th>
          <th colspan="3" class="bordas title-padding borda-right" v-if="!item.componentes_curriculares[0].p3_letivo && !item.componentes_curriculares[0].p1_letivo">
            2° período
          </th>

          <td rowspan="2" class="bordas title-padding">Nota Parc. Final</td>
          <td rowspan="2" class="bordas title-padding">Rec. Final</td>
          <td rowspan="2" class="bordas title-padding">Resul. Anual</td>
          <td rowspan="2" class="bordas title-padding">Total Aulas de Dadas</td>
          <td rowspan="2" class="bordas title-padding">Total de Frequência</td>

          <td rowspan="2" class="bordas title-padding">% Frequência</td>
        </tr>
        <tr align="center">
          <td class="bordas title-padding">Notas:</td>
          <td class="bordas title-padding">Aulas Dadas:</td>
          <td class="bordas title-padding borda-right">Frequência</td>

          <td class="bordas title-padding">Notas:</td>
          <td class="bordas title-padding">Aulas Dadas:</td>
          <td class="bordas title-padding borda-right">Frequência</td>
        </tr>
      </thead>

      <tbody>
        <tr
          class=""
          align="center"
          v-for="(componente, index) in item.info_disciplina_por_area"
          :key="index"
        >
          <td class="borda-top-1px borda-right-1px">
            <div class="borda-top-1px">
              {{ index }}
            </div>
          </td>
          <td class="borda-top-1px borda-right">
            <div
              class="borda-top-1px linhas"
              v-for="(disciplina, index2) in componente"
              :key="index2"
            >
              {{ disciplina.nome_componente }}
            </div>
          </td>

          <td class="borda-top-1px borda-right-1px" v-if="item.componentes_curriculares[0].p1_letivo">
            <div
              class="borda-top-1px"
              v-for="(disciplina, index2) in componente"
              :key="index2"
            >
              <span v-if="disciplina.p1_letivo.nota">{{ disciplina.p1_letivo.nota }}</span>
              <span v-else>0</span>
            </div>
          </td>

          <td class="borda-top-1px borda-right-1px" v-if="item.componentes_curriculares[0].p3_letivo">
            <div
              class="borda-top-1px"
              v-for="(disciplina, index2) in componente"
              :key="index2"
            >
              <span v-if="disciplina.p3_letivo.nota && disciplina.p3_letivo.nota !== 'NaN'">{{ disciplina.p3_letivo.nota }}</span>
              <span v-else>0</span>
            </div>
          </td>
          <td class="borda-top-1px borda-right-1px" v-if="!item.componentes_curriculares[0].p1_letivo && !item.componentes_curriculares[0].p3_letivo">
            <div
              class="borda-top-1px"
              v-for="(disciplina, index2) in componente"
              :key="index2"
            >
              <span>-</span>
            </div>
          </td>

          <td class="borda-top-1px borda-right-1px" v-if="item.componentes_curriculares[0].p1_letivo">
            <div
              class="borda-top-1px"
              v-for="(disciplina, index2) in componente"
              :key="index2"
            >
              <span v-if="disciplina.p1_letivo.aulas_dadas">{{ disciplina.p1_letivo.aulas_dadas }}</span>
              <span v-else>0</span>

            </div>
          </td>
          <td class="borda-top-1px borda-right-1px" v-if="item.componentes_curriculares[0].p3_letivo">
            <div
              class="borda-top-1px"
              v-for="(disciplina, index2) in componente"
              :key="index2"
            >
              <span v-if="disciplina.p3_letivo.aulas_dadas">{{ disciplina.p3_letivo.aulas_dadas }}</span>
              <span v-else>0</span>

            </div>
          </td>
          <td class="borda-top-1px borda-right-1px" v-if="!item.componentes_curriculares[0].p1_letivo &&!item.componentes_curriculares[0].p3_letivo">
            <div
              class="borda-top-1px"
              v-for="(disciplina, index2) in componente"
              :key="index2"
            >
              <span>-</span>

            </div>
          </td>

          <td class="borda-top-1px borda-right-1px" v-if="item.componentes_curriculares[0].p1_letivo">
            <div
              class="borda-top-1px"
              v-for="(disciplina, index2) in componente"
              :key="index2"
            >
              <span v-if="disciplina.p1_letivo.presenca">{{ disciplina.p1_letivo.presenca }}</span>
              <span v-else>0</span>
            </div>
          </td>
          <td class="borda-top-1px borda-right-1px" v-if="item.componentes_curriculares[0].p3_letivo">
            <div
              class="borda-top-1px"
              v-for="(disciplina, index2) in componente"
              :key="index2"
            >
              <span v-if="disciplina.p3_letivo.presenca">{{ disciplina.p3_letivo.presenca }}</span>
              <span v-else>0</span>
            </div>
          </td>
          <td class="borda-top-1px borda-right-1px" v-if="!item.componentes_curriculares[0].p1_letivo && !item.componentes_curriculares[0].p3_letivo">
            <div
              class="borda-top-1px"
              v-for="(disciplina, index2) in componente"
              :key="index2"
            >
              <span>-</span>
            </div>
          </td>

          <td class="borda-top-1px borda-right-1px" v-if="item.componentes_curriculares[0].p2_letivo">
            <div
              class="borda-top-1px"
              v-for="(disciplina, index2) in componente"
              :key="index2"
            >
              <span v-if="disciplina.p2_letivo.nota"> {{ disciplina.p2_letivo.nota }}</span>
              <span v-else>0</span>
            </div>
          </td>
          <td class="borda-top-1px borda-right-1px" v-if="item.componentes_curriculares[0].p4_letivo">
            <div
              class="borda-top-1px"
              v-for="(disciplina, index2) in componente"
              :key="index2"
            >
              <span v-if="disciplina.p4_letivo.nota && disciplina.p4_letivo.nota !== 'NaN'"> {{ disciplina.p4_letivo.nota }}</span>
              <span v-else>0</span>
            </div>
          </td>
          <td class="borda-top-1px borda-right-1px" v-if="!item.componentes_curriculares[0].p2_letivo && !item.componentes_curriculares[0].p4_letivo">
            <div
              class="borda-top-1px"
              v-for="(disciplina, index2) in componente"
              :key="index2"
            >
              <span>-</span>
            </div>
          </td>


          <td class="borda-top-1px borda-right-1px" v-if="item.componentes_curriculares[0].p2_letivo">
            <div
              class="borda-top-1px"
              v-for="(disciplina, index2) in componente"
              :key="index2"
            >
              <span v-if="disciplina.p2_letivo.aulas_dadas">{{ disciplina.p2_letivo.aulas_dadas }}</span>
              <span v-else>0</span>
            </div>
          </td>
          <td class="borda-top-1px borda-right-1px" v-if="item.componentes_curriculares[0].p4_letivo">
            <div
              class="borda-top-1px"
              v-for="(disciplina, index2) in componente"
              :key="index2"
            >
              <span v-if="disciplina.p4_letivo.aulas_dadas">{{ disciplina.p4_letivo.aulas_dadas }}</span>
              <span v-else>0</span>
            </div>
          </td>
          <td class="borda-top-1px borda-right-1px" v-if="!item.componentes_curriculares[0].p2_letivo && !item.componentes_curriculares[0].p4_letivo">
            <div
              class="borda-top-1px"
              v-for="(disciplina, index2) in componente"
              :key="index2"
            >
              <span>-</span>
            </div>
          </td>


          <td class="borda-top-1px borda-right-1px" v-if="item.componentes_curriculares[0].p2_letivo">
            <div
              class="borda-top-1px"
              v-for="(disciplina, index2) in componente"
              :key="index2"
            >
              <span v-if="disciplina.p2_letivo.presenca">{{ disciplina.p2_letivo.presenca }}</span>
              <span v-else>0</span>
            </div>
          </td>
          <td class="borda-top-1px borda-right-1px" v-if="item.componentes_curriculares[0].p4_letivo">
            <div
              class="borda-top-1px"
              v-for="(disciplina, index2) in componente"
              :key="index2"
            >
              <span v-if="disciplina.p4_letivo.presenca">{{ disciplina.p4_letivo.presenca }}</span>
              <span v-else>0</span>
            </div>
          </td>
          <td class="borda-top-1px borda-right-1px" v-if="!item.componentes_curriculares[0].p2_letivo && !item.componentes_curriculares[0].p4_letivo">
            <div
              class="borda-top-1px"
              v-for="(disciplina, index2) in componente"
              :key="index2"
            >
              <span>-</span>
            </div>
          </td>

          <td class="borda-top-1px borda-right-1px">
            <div
              class="borda-top-1px"
              v-for="(disciplina, index2) in componente"
              :key="index2"
            >
              <span v-if="disciplina.nota_final">{{ disciplina.nota_final }}</span>
              <span v-else>0</span>
            </div>
          </td>

          <td class="borda-top-1px borda-right-1px">
            <div
              class="borda-top-1px"
              v-for="(disciplina, index2) in componente"
              :key="index2"
            >
              <span v-if="disciplina.calculo_geral.recuperacao_final == null"
              >-</span
              >
              <span v-else>{{
                disciplina.calculo_geral.recuperacao_final
              }}</span>
            </div>
          </td>

          <td class="borda-top-1px borda-right-1px">
            <div
              class="borda-top-1px"
              v-for="(disciplina, index2) in componente"
              :style="disciplina.calculo_geral.resultado_anual < 50 ? 'color: red' : 'color:blue'"
              :key="index2"
            >
              <span v-if="disciplina.calculo_geral.resultado_anual"> {{ disciplina.calculo_geral.resultado_anual }}</span>
              <span v-else>0</span>
            </div>
          </td>
          <td class="borda-top-1px borda-right-1px">
            <div
              class="borda-top-1px"
              v-for="(disciplina, index2) in componente"
              :key="index2"
            >
              {{ disciplina.calculo_geral.total_aulas_dadas }}
            </div>
          </td>
          <td class="borda-top-1px borda-right-1px">
            <div
              class="borda-top-1px"
              v-for="(disciplina, index2) in componente"
              :key="index2"
            >
              {{ disciplina.calculo_geral.total_frequencia }}
            </div>
          </td>
          <td class="borda-top-1px">
            <div
              class="borda-top-1px"
              v-for="(disciplina, index2) in componente"
              :key="index2"
            >
              <span v-if="disciplina.calculo_geral.porcentagem_frequencia == 'CE'">CE</span>
              <span v-else-if="disciplina.calculo_geral.porcentagem_frequencia >= '75%'">+75</span>
              <span v-else-if="disciplina.calculo_geral.porcentagem_frequencia == '100%'">+75</span>
              <span v-else>-75</span>
            </div>
          </td>
        </tr>

        <tr align="center" class="borda-top">
          <td
            class="borda-bottom borda-right-1px"
            colspan="2"
            style="padding: 10px !important"
          >
            Cálculo Global de Aulas Dadas:
          </td>
          <td colspan="1" class="borda-bottom borda-right-1px" v-if="item.tipo_serie === 1" style="padding: 10px !important">
            {{ item.info_disciplina_por_area['Linguagens'][0].calculo_geral.total_aulas_dadas }}
          </td>
          <td colspan="1" class="borda-bottom borda-right-1px" v-else style="padding: 10px !important">
            {{ item.calculo_global_aulas }}
          </td>

          <td class="borda-bottom borda-right-1px" colspan="3" style="padding: 10px !important">
            Cálculo Global de Frequência:
          </td>

          <td class="borda-bottom borda-right-1px" colspan="2" v-if="item.tipo_serie === 1" style="padding: 10px !important">
            {{ item.controle_de_frequencia.total_frequencia }}
          </td>

          <td class="borda-bottom borda-right-1px" colspan="2" v-else style="padding: 10px !important">
            {{ item.calculo_global_frequencia }}
          </td>
          <td class="borda-bottom borda-right-1px" colspan="3" style="padding: 10px !important">
            Percentual Global de Frequência:
          </td>
          <td
            class="borda-bottom borda-right-1px"
            colspan="3"
            style="padding: 10px !important"
            v-if="item.controle_de_frequencia.percentual_frequencia >= '75%'"
          >
            +75
          </td>
          <td class="borda-bottom borda-right-1px" colspan="3" style="padding: 10px !important" v-else>-75</td >
        </tr>

        <!-- eja1 -->

        <tr v-if="item.tipo_serie === 1" align="center">
          <th colspan="12" rowspan="2" class="">
            Legenda: {{nomenclaturaSituacaoAluno.aprovado}} ({{nomenclaturaSituacaoAluno.aprovadoAbreviacao}}),
            {{ nomenclaturaSituacaoAluno.aprovadoPeloConselho }} ({{ nomenclaturaSituacaoAluno.aprovadoPeloConselhoAbreviacao }}),
            {{ nomenclaturaSituacaoAluno.aprovadoCreche }} ({{ nomenclaturaSituacaoAluno.aprovadoCrecheAbreviacao }}),
            {{nomenclaturaSituacaoAluno.reprovado}} ({{nomenclaturaSituacaoAluno.reprovadoAbreviacao}}),
            {{ nomenclaturaSituacaoAluno.retencaoJustificada }} ({{ nomenclaturaSituacaoAluno.retencaoJustificadaAbreviacao }}),
            {{ nomenclaturaSituacaoAluno.transferido }} ({{ nomenclaturaSituacaoAluno.transferidoAbreviacao }}),
            <!-- {{ nomenclaturaSituacaoAluno.transferenciaExternaCreche }} ({{ nomenclaturaSituacaoAluno.transferenciaExternaCrecheAbreviacao }}), -->
            {{ nomenclaturaSituacaoAluno.evadido }} ({{ nomenclaturaSituacaoAluno.evadidoAbreviacao }}),
            {{ nomenclaturaSituacaoAluno.falecido }} ({{ nomenclaturaSituacaoAluno.falecidoAbreviacao }})
          </th>
          <th
            class="bordas"
            rowspan="1"
            colspan="2"
            style="text-transform: uppercase; vertical-align: middle; !important"
          >
            RESULTADO FINAL
          </th>
        </tr>

        <tr v-if="item.tipo_serie === 1" align="center">
          <th
            class="borda-bottom bordas"
            align="center"
            v-if="item.resultado_final === 'reprovado'"
            rowspan="2"
            colspan="3"
          >
            {{nomenclaturaSituacaoAluno.reprovado}}
          </th>
          <th
            class="borda-bottom"
            align="center"
            v-if="item.resultado_final === 'rj'"
            rowspan="2"
            colspan="3"
          >
            {{nomenclaturaSituacaoAluno.retencaoJustificada}}
          </th>
          <th
            class="borda-bottom bordas"
            align="center"
            v-if="item.resultado_final === 'aprovado'"
            rowspan="2"
            colspan="3"
          >
            {{nomenclaturaSituacaoAluno.aprovado}}
          </th>
          <th
            class="borda-bottom"
            align="center"
            v-if="item.resultado_final === 'apc'"
            rowspan="2"
            colspan="3"
          >
            {{nomenclaturaSituacaoAluno.aprovadoPeloConselho}}
          </th>
          <th
            class="borda-bottom bordas"
            align="center"
            v-if="item.resultado_final === 'transferido'"
            rowspan="2"
            colspan="3"
          >
            Transferido
          </th>
          <th
            class="borda-bottom bordas"
            align="center"
            v-if="item.resultado_final === 'evadido'"
            rowspan="2"
            colspan="3"
          >
            Evadido
          </th>
        </tr>
        <tr v-if="item.tipo_serie === 1" align="right">
          <td colspan="12" style="padding-top: 1px;">
            Cardoso Moreira, ________de______________ de dois mil e vinte e
            ____________.
          </td>
        </tr>

        <!-- eja 2 -->
        <tr v-if="item.tipo_serie === 2" align="center">
          <td colspan="1" class="bordas">PROGRESSÃO PARCIAL</td>
          <td colspan="3" class="bordas">Componentes Curriculares</td>
          <td colspan="2" class="bordas">Ano de Escolaridade</td>
          <td colspan="1" class="bordas">AULAS DADAS</td>
          <td colspan="2" class="bordas">Percentual Frequência</td>
          <td colspan="2" class="bordas">Ratificação Supervisor</td>
          <th
            class="bordas"
            rowspan="1"
            colspan="3"
            style="text-transform: uppercase; vertical-align: middle; !important"
          >
            RESULTADO FINAL
          </th>
        </tr>

        <tr v-if="item.tipo_serie === 2" align="center">
          <td colspan="1" rowspan="1" class="bordas">Dependência(s)</td>
          <td colspan="3" class="bordas"></td>
          <td colspan="2" class="bordas"></td>
          <td colspan="1" class="bordas"></td>
          <td colspan="2" class="bordas"></td>
          <td colspan="2" class="bordas"></td>
          <th
            class="borda-bottom"
            align="center"
            v-if="item.resultado_final === 'reprovado'"
            rowspan="2"
            colspan="3"
          >
            {{nomenclaturaSituacaoAluno.reprovado}}
          </th>
          <th
            class="borda-bottom"
            align="center"
            v-if="item.resultado_final === 'rj'"
            rowspan="2"
            colspan="3"
          >
            {{nomenclaturaSituacaoAluno.retencaoJustificada}}
          </th>
          <th
            class="borda-bottom"
            align="center"
            v-if="item.resultado_final === 'aprovado'"
            rowspan="2"
            colspan="3"
          >
            {{nomenclaturaSituacaoAluno.aprovado}}
          </th>
          <th
            class="borda-bottom"
            align="center"
            v-if="item.resultado_final === 'apc'"
            rowspan="2"
            colspan="3"
          >
            {{nomenclaturaSituacaoAluno.aprovadoPeloConselho}}
          </th>
          <th
            class="borda-bottom"
            align="center"
            v-if="item.resultado_final === 'transferido'"
            rowspan="2"
            colspan="3"
          >
            Transferido
          </th>
          <th
            class="borda-bottom"
            align="center"
            v-if="item.resultado_final === 'evadido'"
            rowspan="2"
            colspan="3"
          >
            Evadido
          </th>
        </tr>
        <tr v-if="item.tipo_serie === 2" align="center">
          <td colspan="1" rowspan="1" class="borda-bottom borda-right-1px">
            Dependência(s)
          </td>

          <td colspan="3" class="borda-bottom borda-right-1px"></td>
          <td colspan="2" class="borda-bottom borda-right-1px"></td>
          <td colspan="1" class="borda-bottom borda-right-1px"></td>
          <td colspan="2" class="borda-bottom borda-right-1px"></td>
          <td colspan="2" class="borda-bottom borda-right-1px"></td>
        </tr>
        <tr v-if="item.tipo_serie === 2" align="center">
          <th colspan="14" class="">
            Legenda: {{nomenclaturaSituacaoAluno.aprovado}} ({{nomenclaturaSituacaoAluno.aprovadoAbreviacao}}),
            {{ nomenclaturaSituacaoAluno.aprovadoPeloConselho }} ({{ nomenclaturaSituacaoAluno.aprovadoPeloConselhoAbreviacao }}),
            {{ nomenclaturaSituacaoAluno.aprovadoCreche }} ({{ nomenclaturaSituacaoAluno.aprovadoCrecheAbreviacao }}),
            {{nomenclaturaSituacaoAluno.reprovado}} ({{nomenclaturaSituacaoAluno.reprovadoAbreviacao}}),
            {{ nomenclaturaSituacaoAluno.retencaoJustificada }} ({{ nomenclaturaSituacaoAluno.retencaoJustificadaAbreviacao }}),
            {{ nomenclaturaSituacaoAluno.transferido }} ({{ nomenclaturaSituacaoAluno.transferidoAbreviacao }}),
            <!-- {{ nomenclaturaSituacaoAluno.transferenciaExternaCreche }} ({{ nomenclaturaSituacaoAluno.transferenciaExternaCrecheAbreviacao }}), -->
            {{ nomenclaturaSituacaoAluno.evadido }} ({{ nomenclaturaSituacaoAluno.evadidoAbreviacao }}),
            {{ nomenclaturaSituacaoAluno.falecido }} ({{ nomenclaturaSituacaoAluno.falecidoAbreviacao }})
          </th>
        </tr>
        <tr v-if="item.tipo_serie === 2" align="right">
          <td colspan="14" style="padding-top: 15px;">
            Cardoso Moreira, ________de______________ de dois mil e vinte e
            ____________.
          </td>
        </tr>
      </tbody>
    </table>
    <!-- <table class="col-12 borda-externa tabela">
      <thead>
        <tr align="center">
          <th rowspan="2" class="bordas title-padding">
            COMPONENTES CURRICULARES
          </th>
          <th colspan="3" class="bordas title-padding">1° periodo</th>
          <th colspan="3" class="bordas title-padding">2° periodo</th>
          <td rowspan="2" class="bordas title-padding">Nota Parc. Final</td>
          <td rowspan="2" class="bordas title-padding">Rec. Final</td>
          <td rowspan="2" class="bordas title-padding">Resul. Anual</td>
          <td rowspan="2" class="bordas title-padding">Total Aulas de Dadas</td>
          <td rowspan="2" class="bordas title-padding">Total de Frequência</td>
          <td rowspan="2" class="bordas title-padding">
            Cálculo Global de Aulas Dadas
          </td>
          <td rowspan="2" class="bordas title-padding">
            Cálculo Global de Frequência
          </td>
          <td rowspan="2" class="bordas title-padding">% frequencia</td>
          <td rowspan="2" class="bordas title-padding">Visto Supervisor</td>
        </tr>
        <tr align="center">
          <td class="bordas title-padding">Notas:</td>
          <td class="bordas title-padding">Aulas Dadas:</td>
          <td class="bordas title-padding">Frequência</td>

          <td class="bordas title-padding">Notas:</td>
          <td class="bordas title-padding">Aulas Dadas:</td>
          <td class="bordas title-padding">Frequência</td>
        </tr>
      </thead>

      <tbody>
        <tr
          align="center"
          v-for="(componente, index) in item.componentes_curriculares"
          :key="index"
        >
          <td class="bordas">
            {{ componente.nome_componente }}
          </td>

          <td class="bordas">
            {{ componente.p1_letivo.nota }}
          </td>
          <td class="bordas">
            {{ componente.p1_letivo.aulas_dadas }}
          </td>
          <td class="bordas">{{ componente.p1_letivo.presenca }}</td>

          <td class="bordas">
            {{ componente.p2_letivo.nota }}
          </td>
          <td class="bordas">
            {{ componente.p2_letivo.aulas_dadas }}
          </td>
          <td class="bordas">{{ componente.p2_letivo.presenca }}</td>

          <td class="bordas">{{ componente.nota_final }}</td>
          <td
            class="bordas"
            v-if="componente.calculo_geral.recuperacao_final == null"
          >
            -
          </td>
          <td class="bordas" v-else>
            {{ componente.calculo_geral.recuperacao_final }}
          </td>
          <td class="bordas">{{ componente.calculo_geral.resultado_anual }}</td>
          <td class="bordas">
            {{ componente.calculo_geral.total_aulas_dadas }}
          </td>
          <td class="bordas">
            {{ componente.calculo_geral.total_frequencia }}
          </td>
          <td class="bordas">{{ item.calculo_global_aulas }}</td>
          <td class="bordas">{{ item.calculo_global_frequencia }}</td>
          <td class="bordas">
            {{ componente.calculo_geral.porcentagem_frequencia }}%
          </td>
          <td rowspan=""></td>
        </tr>

        <tr v-if="info.serie_id.tipo === 1" align="center">
          <th colspan="13" class="bordas">
            Legenda: Promovido-(Pr), Retido-(Ret), Evadido-(Ev) ou
            Transferido-(Tr), Conteúdo Explorado-(CE).
          </th>
          <th
            class="bordas"
            colspan="2"
            style="text-transform: uppercase"
            v-if="item.resultado_final === 'reprovado'"
          >
            RESULTADO FINAL: Ret
          </th>
          <th
            class="bordas"
            colspan="5"
            style="text-transform: uppercase"
            v-if="item.resultado_final === 'aprovado'"
          >
            RESULTADO FINAL: Pr
          </th>
          <th
            class="bordas"
            colspan="5"
            style="text-transform: uppercase"
            v-if="item.resultado_final === 'evadido'"
          >
            RESULTADO FINAL: Ev
          </th>
          <th
            class="bordas"
            colspan="5"
            style="text-transform: uppercase"
            v-if="item.resultado_final === 'Transferido'"
          >
            RESULTADO FINAL: Tr
          </th>
        </tr>

        <tr v-if="info.serie_id.tipo === 2" align="center">
          <td colspan="1" class="bordas">PROGRESSÃO PARCIAL</td>
          <td colspan="3" class="bordas">Componentes Curriculares</td>
          <td colspan="1" class="bordas">Ano de Escolaridade</td>
          <td colspan="1" class="bordas">VALOR PERCENTUAL</td>
          <td colspan="2" class="bordas">AULAS DADAS</td>
          <td colspan="2" class="bordas">Percentual Frequência</td>
          <td colspan="2" class="bordas">Ratificação Supervisor</td>
          <th
            class="bordas"
            rowspan="3"
            colspan="3"
            style="text-transform: uppercase; vertical-align: middle; !important"
            v-if="item.resultado_final === 'reprovado'"
          >
            RESULTADO FINAL: Ret
          </th>
          <th
            class="bordas"
            rowspan="3"
            colspan="3"
            style="text-transform: uppercase; vertical-align: middle; !important"
            v-if="item.resultado_final === 'aprovado'"
          >
            RESULTADO FINAL: Pr
          </th>
          <th
            class="bordas"
            rowspan="3"
            colspan="3"
            style="text-transform: uppercase; vertical-align: middle; !important"
            v-if="item.resultado_final === 'evadido'"
          >
            RESULTADO FINAL: Ev
          </th>
          <th
            class="bordas"
            rowspan="3"
            colspan="3"
            style="text-transform: uppercase; vertical-align: middle; !important"
            v-if="item.resultado_final === 'Transferido'"
          >
            RESULTADO FINAL: Tr
          </th>
          <th colspan="2" rowspan="3">
            Percentual Global de frequência: <br />
            {{ item.controle_de_frequencia.percentual_frequencia }}%
          </th>
        </tr>

        <tr v-if="info.serie_id.tipo === 2" align="center">
          <td colspan="1" rowspan="2" class="bordas">Dependencia(s)</td>
          <td colspan="3" class="bordas"></td>
          <td colspan="1" class="bordas"></td>
          <td colspan="1" class="bordas"></td>
          <td colspan="2" class="bordas"></td>
          <td colspan="2" class="bordas"></td>
          <td colspan="2" class="bordas"></td>
        </tr>
        <tr v-if="info.serie_id.tipo === 2" align="center">
          <td colspan="3" class="bordas"></td>
          <td colspan="1" class="bordas"></td>
          <td colspan="1" class="bordas"></td>
          <td colspan="2" class="bordas"></td>
          <td colspan="2" class="bordas"></td>
          <td colspan="2" class="bordas"></td>
        </tr>
      </tbody>
    </table> -->

    <table class="col-12 borda-externa" style="min-width: 1500px;">
      <tbody>
        <tr>
          <td colspan="3">Observação:
            <label v-if="item.resultado_final === 'apc' && item.numeroAta" >Aprovado pela ata {{ item.numeroAta }}</label> <br>

            {{ item.observacao_geral }}</td>
        </tr>
        <tr>

        </tr>
        <tr align="center" style="margin-top: 500px;">
          <td style="padding-top: 30px;">___________________</td>
          <td style="padding-top: 30px;">___________________</td>
          <td style="padding-top: 30px;">___________________</td>
        </tr>
        <tr align="center">
          <td>Secretário (a)</td>
          <td>Diretor (a)</td>
          <td>Supervisor (a)</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { nomenclaturaSituacaoAluno } from '@/utils/nomenclatura';
import axios from "axios";


export default defineComponent({
  components: {},
  props: {
    cabecalho: {},
    dados: "",
    info: {},
  },
  data() {
    return {
      base_url: axios.defaults.baseURL.replace("/api/v1", ""),
      nomenclaturaSituacaoAluno: nomenclaturaSituacaoAluno,
      parteDiversificada: [{
        area: "",
        calculo_geral: {
          nota_parcial_final: "CE",
          porcentagem_frequencia: "CE",
          recuperacao_final: "CE",
          resultado_anual: "CE",
          total_aulas_dadas: "CE",
          total_frequencia: "CE",
        },
        nome_componente: "Atividade Diversificada",
        nota_final: "CE",
        observacao: "",
        p1_letivo: {
          aulas_dadas: "CE",
          nota: "CE",
          percentual: "CE",
          presenca: "CE",
        },
        p2_letivo: {
          aulas_dadas: "CE",
          nota: "CE",
          percentual: "CE",
          presenca: "CE",
        },
        p3_letivo: {
          aulas_dadas: "CE",
          nota: "CE",
          percentual: "CE",
          presenca: "CE",
        },
        p4_letivo: {
          aulas_dadas: "CE",
          nota: "CE",
          percentual: "CE",
          presenca: "CE",
        },
      }],
    };
  },

  methods: {},
  beforeMount() {
    this.dados.forEach((element) => {
      // element.componentes_curriculares.push(this.parteDiversificada);
      element.info_disciplina_por_area['Atividade Diversificada'] = this.parteDiversificada;
    });
  },
});
</script>

<style scoped>
.borda-externa {
  border: 2px #000 solid;
}
.borda-right {
  border-right: 2px #000 solid;
  /* width: 50%; */
}
.borda-right-1px {
  border-right: 1px solid #000;
}
.borda-bottom {
  border-bottom: 2px #000 solid;
}
.borda-bottom-1px {
  border-bottom: 1px solid #000;
}
.borda-top-1px {
  border-top: 1px #000 solid;
}
.borda-top {
  border-top: 2px #000 solid;
}
.bordas {
  border: 1px solid #000;
}
.title-padding {
  padding: 0px 5px 0px 5px;
}
.tabela {
  max-height: 2px;
  /* margin-bottom: 30px;
  border-collapse: collapse; */
}

.button-generate {
  margin-top: 10px;
  margin-right: 5px;
}
.select {
  appearance: revert;
  width: 100%;
}

.uppercase {
  text-transform: uppercase;
}
.tamanho-titulos-dias {
  width: 3%;
}
.alinhamento {
  margin-top: -15px;
  padding: 14px;
}

.alinhameneto-margem {
  margin-left: -12px;
  margin-right: -12px;
}

.card-filtro {
  background-color: #f8f9fa;
  border: 1px solid #dee2e6;
  margin-top: 15px;
}
</style>
